import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { motion } from "framer-motion";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
import Services from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import Blurb from "components/features/TwoColSingleFeatureWithStats2.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/FiveColumnDark.js";

let mailStatus = new URLSearchParams(window.location.search).get('mail');
const MailNotification = motion.custom(styled.div`
  ${tw`w-screen px-6 pt-6 pb-12 text-gray-900 bg-primary-500 transition duration-300`}
`);
const NotificationText = tw.p`m-0 text-center text-sm md:text-base lg:text-lg font-medium leading-relaxed text-gray-100`;
const Container = styled.div(
  tw`relative -mx-8 -mt-8`
);

export default () => {
  const notification = (
    <Container>
      <MailNotification>
        <NotificationText>Thanks for reaching out! We'll be in touch soon.</NotificationText>
      </MailNotification>
    </Container>
  );

  return (
    <AnimationRevealPage disabled={mailStatus === "success" ? true : false}>
      {mailStatus === "success" ? notification : null}
      <Hero />
      <Blurb />
      <Services />
      <ContactUsForm />
      <Footer />
    </AnimationRevealPage>
  );
};
