import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Link, animateScroll as scroll } from "react-scroll";

import defaultCardImage from "images/shield-icon.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

import ServerIcon from "../../images/server.svg";
import WifiIcon from "../../images/wifi.svg";
import RadioIcon from "../../images/radio.svg";
import ActivityIcon from "../../images/activity.svg";
import ToolIcon from "../../images/tool.svg";
import VideoIcon from "../../images/video.svg";

const InlineLink = tw(Link)`
  transition duration-300
  pb-1 border-b-2 border-primary-400 hover:border-gray-100 hocus:text-gray-100
`;

const Container = tw.div`relative bg-primary-900 -mx-8 px-8 text-gray-100`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center text-gray-300`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6`}
  }

  .title {
    ${tw`tracking-wider font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-gray-400 leading-snug`}
  }
`;

export default ({
  heading = "Our Services",
  subheading = "",
  description = (
      <>
      Here's just a small snippet of what we can do for you. Don't see what you're looking for? <InlineLink href="#contact" to="contact" smooth="true">Get in touch</InlineLink> and we'll make it happen!
      </>
  )
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: ShieldIconImage,
      title: "Secure",
      description: "We strictly only deal with vendors that provide top notch security infrastructure."
    },
    { imageSrc: SupportIconImage, title: "24/7 Support" },
    { imageSrc: CustomizeIconImage, title: "Customizable" },
    { imageSrc: ReliableIconImage, title: "Reliable" },
    { imageSrc: FastIconImage, title: "Fast" },
    { imageSrc: SimpleIconImage, title: "Easy" }
  ];
  const cards = [
    {
      imageSrc: ServerIcon,
      title: "Network Design",
      description: "Let us architect a solution to fit your needs, using technologies that fit your budget."
    },
    {
      imageSrc: WifiIcon,
      title: "WiFi Systems",
      description: "Take charge of your home or business WiFi. We can help you with coverage inside and out."
    },
    {
      imageSrc: RadioIcon,
      title: "Wireless Bridges",
      description: "Can't run a cable? Let wireless bridge the gap, whether it's a few feet or a few miles."
    },
    {
      imageSrc: ToolIcon,
      title: "Troubleshooting",
      description: "Having an issue with your current network? Let us help so you can focus on what's most important."
    },
    {
      imageSrc: ActivityIcon,
      title: "Cloud-Based Monitoring",
      description: "Want to monitor your network without a 24/7 commitment? Let our systems do the work."
    },
    {
      imageSrc: VideoIcon,
      title: "IP Camera Systems",
      description: "Protect your home or business with scalable video surveillance."
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id="services">
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
