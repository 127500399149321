import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Link, animateScroll as scroll } from "react-scroll";

import logo from "../../images/logo-full-dark.svg";
import Header, { NavLink, ExternalNavLink, NavLinks, PrimaryLink, AltLogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8`}
  ${DesktopNavLinks} ${NavLink}, ${ExternalNavLink}, ${AltLogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

export default () => {
  const darkLogoLink = (
      <AltLogoLink href="/">
        <img src={logo} alt="logo" />
      </AltLogoLink>
  );
  return (
    <StyledHeader logoLink={darkLogoLink} />
  );
};
