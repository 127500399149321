import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */


import MainLandingPage from "pages/Main.js";
import PortalPage from "pages/Portal.js";
import TermsPage from "pages/TermsOfService.js";
import PrivacyPage from "pages/PrivacyPolicy.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

export default function App() {

  return (
    <Router>
      <Switch>
        <Route path="/portal">
          <PortalPage />
        </Route>
        <Route path="/terms-of-service">
          <TermsPage />
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPage />
        </Route>
        <Route path="/">
          <MainLandingPage />
        </Route>
      </Switch>
    </Router>
  );
}
